.MuiOutlinedInput-notchedOutline > legend {
  width: unset;
}

.MuiTypography-root {
  color: #fff;
}

.MuiButton-root {
  text-transform: none !important;

  // &:hover {
  //   filter: drop-shadow(0 0 8px #966740a0);
  // }

  &.Mui-disabled {
    text-shadow: none;
    background: -webkit-linear-gradient(0deg, #95A3B8, #95A3B8), linear-gradient(0deg, #ABBBD3, #ABBBD3), radial-gradient(45.57% 73.91% at 49.56% 100%, rgba(206, 225, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%) !important;
    border-color: #ABBBD3 !important;
    box-shadow: none !important;
  }
}

.MuiContainer-maxWidthXl {
  max-width: 1760px !important;
}

.MuiTableContainer-root {
  margin: 16px 0px;

  .MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root {
    border-bottom: none;
  }
  .MuiTableRow-root:hover {
    background-color: #00000006;
  }
  .MuiTableCell-head {
    padding: 8px 16px;
    text-align: center;
  }
  .MuiTableCell-body {
    padding: 8px 16px;
  }
  .MuiTableCell-body > .MuiCheckbox-root {
    padding: 6px;
  }
}

.MuiTabs-root,
.MuiTab-root {
  min-height: 40px !important;
  text-transform: none !important;
}

.MuiCheckbox-root {
  padding: 6px !important;
}

.MuiDialog-root {
  .MuiDialog-paper {
    background: url('/assets/images/frame-dialog.png');
    background-size: 100% 100%;
    padding: 20px 20px 10px;
  }

  .MuiDialogTitle-root {
    padding: 8px 16px;
    text-align: center;
    font-size: 20px;
    color: #b7a284;
  }
  .MuiDialogContent-root {
    padding: 16px 16px !important;
  }
  .MuiDialogActions-root {
    padding: 12px 16px;
    border-top: 1px solid #0003;
  }
}

.MuiTabs-root {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #0003;
  }

  .MuiTab-root {
    border: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;

    &.Mui-selected {
      border-color: #0003;
      border-bottom-color: transparent;
    }
  }
}

.MuiAccordion-root {
  &.MuiPaper-root::before {
    content: none;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
}

.MuiStepper-root {
  margin-bottom: 12px;

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 8px;
  }
}

.MuiTimelineItem-missingOppositeContent {
  &:before {
    display: none;
  }
}

.MuiTimelineDot-root {
  background: url('/assets/icons/round-rock-icon.png') no-repeat center center / contain;
  height: 25px;
  width: 23px;
  background-color: inherit !important;
  margin: 0px !important;
  border: none !important;
}

.MuiTimelineConnector-root {
  width: 5px !important;
  background-color: #8e7555 !important;
}

.MuiTimelineContent-root {
  padding-top: 0px !important;
}
