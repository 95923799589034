.container-sliding {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  color: inherit;
  &:hover {
    .holder {
      color: transparent !important;
    }
    .sliding-text span {
      animation: sliding 3s linear infinite;
      color: inherit !important;
    }
  }
}
.holder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.full-length {
  white-space: nowrap;
  width: fit-content;
  opacity: 0;
}
.normal-text {
  width: 100%;
  white-space: nowrap;
}
.sliding-text {
  position: absolute;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
  height: 100%;
  top: 0;
}
.sliding-text span {
  display: inline-block;
  padding-left: 40px;
  white-space: nowrap;
  min-width: 100%;
  height: 100%;
  color: transparent;
}

@keyframes sliding {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
