@import '~tailwindcss/base.css';
@import '~tailwindcss/components.css';
@import '~tailwindcss/utilities.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/component';
@import 'styles/layout';
@import 'styles/mui';
@import '~swiper/swiper.scss';
@import '~swiper/modules/navigation/navigation.scss';
@import '~swiper/modules/pagination/pagination.scss';

body {
  font-family: Avenir, Roboto, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  background-color: #000;
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: Inconsolata, monospace !important;
}

* {
  outline: none !important;
}

iframe {
  display: none;
}

a:hover {
  text-decoration: none !important;
}

:root {
  --color-primary-main: #123456;
}

@font-face {
  font-family: 'Skranji';
  src: url('assets/fonts/Skranji-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Skranji';
  src: url('assets/fonts/Skranji-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/AvenirLTStd-Roman.otf') format('truetype');
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #96846a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
